import React from 'react';
import styles from "./ChangedValueView.module.css";

function ChangedValueView(props) {
    const {newValue, oldValue, changeType} = props;
    let changeString = null;
    let newClass = styles.value;
    if (changeType === "add") {
        changeString = "NEW";
    } else if (changeType === "change") {
        changeString = "UPDATED";
        newClass = newClass + " " + styles.newValue;
    }
    return <div className={styles.changeView}>
        <div className={newClass}>
          {newValue}
        </div>
        {oldValue && <div className={styles.value + " " + styles.oldValue}>
          {oldValue}
        </div>}
        {changeString && <div className={styles.changeType}>
                {changeString}
        </div>}
    </div>
}

export default ChangedValueView;
