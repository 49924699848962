import React from 'react';
import _ from "lodash";
import ChangedValueWithLabel from "./ChangedValueWithLabel";
import styled from 'styled-components';
const Wrapper = styled.div`
    width: 100%;
`
const SubHeading = styled.h5`
    text-align: center;
`
function ResponsiblePartyView(props) {
  const respParty = props.data;
  const changes = props.changes;
  const type = _.get(respParty, "ResponsiblePartyType");
  const typeChange = _.get(changes, "ResponsiblePartyType");
  const name = _.get(respParty, "ResponsiblePartyInvestigatorFullName");
  const nameChange = _.get(changes, "ResponsiblePartyInvestigatorFullName");
  const title = _.get(respParty, "ResponsiblePartyInvestigatorTitle");
  const titleChange = _.get(changes, "ResponsiblePartyInvestigatorTitle");
  const affiliation = _.get(respParty, "ResponsiblePartyInvestigatorAffiliation");
  const affiliationChange = _.get(changes, "ResponsiblePartyInvestigatorAffiliation");
  return <Wrapper>
    <SubHeading>Responsible Party</SubHeading>
    <ChangedValueWithLabel label="Responsible Party Type" newValue={type} {...typeChange}/>
    {name && <ChangedValueWithLabel label="Full Name" newValue={name} {...nameChange}/>}
    {title && <ChangedValueWithLabel label="Title" newValue={title} {...titleChange}/>}
    {affiliation && <ChangedValueWithLabel label="Affiliation" newValue={affiliation} {...affiliationChange}/>}
  </Wrapper>
}

export default ResponsiblePartyView;
