import axios from 'axios'

const api = axios.create({
    baseURL: 'https://www.alicantotrials.org/api'
})

export const insertTrial = payload => api.post(`/trial`, payload)
export const getAllTrials = (page=1, pageSize=10, filtered={}, sortBy=null, order=null) =>
    api.get("/trials", {params: {page:page, limit: pageSize, sortBy: sortBy, order: order, ...filtered}});
export const updateTrialById = (id, payload) => api.put(`/trial/${id}`, payload)
export const deleteTrialById = id => api.delete(`/trial/${id}`)
export const getTrialById = id => api.get(`/trial/${id}`)
export const getDiffById= id => api.get(`/changes/diff/${id}`)
export const getCountries = () => api.get(`/countries`)
export const getStatuses = () => api.get(`/statuses`)
export const getConditions = () => api.get(`/conditions`)
export const getSubByEmail = email => api.get(`/notification/sub/${email}`)
export const sendVerEmail = email => api.post(`/notification/sendVer/${email}`)
export const verifyEmail = code => api.post(`/notification/verify/${code}`)
export const isVerified = email => api.get(`/notification/isVerified/${email}`)
export const subscribeToTrial = (email, id) => api.post(`/notification/addNotTrial/${email}/${id}`)
export const unsubscribeToTrial = (email, id) => api.post(`/notification/rmvNotTrial/${email}/${id}`)
export const subscribeToCondition = (email, condition) => api.post(`/notification/addNotCon/${email}/${condition}`)
export const unsubscribeToCondition = (email, condition) => api.post(`/notification/rmvNotCon/${email}/${condition}`)

const apis = {
    insertTrial,
    getAllTrials,
    updateTrialById,
    deleteTrialById,
    getTrialById,
    getCountries,
    getStatuses,
    getDiffById,
    getConditions,
    getSubByEmail,
    sendVerEmail,
    verifyEmail,
    isVerified,
    subscribeToTrial,
    unsubscribeToTrial,
    subscribeToCondition,
    unsubscribeToCondition
}

export default apis;
