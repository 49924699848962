import React from 'react';
import _ from "lodash";
import ChangedValueWithLabel from "./ChangedValueWithLabel";
import ListView from "./ListView";
import styled from 'styled-components';
const Wrapper = styled.div`
    width: 90%;
    margin: auto;
`
function DesignView(props) {
  const designModule = props.data;
  const changes = props.changes;
  const studyType = _.get(designModule, "StudyType");
  const studyTypeChange = _.get(changes, "StudyType");
  const duration = _.get(designModule, "TargetDuration");
  const durationChange = _.get(changes, "TargetDuration");
  const patientRegistry = _.get(designModule, "PatientRegistry");
  const patientRegistryChange = _.get(changes, "PatientRegistry");
  const models = _.get(designModule, "DesignInfo.DesignOperationalModelList.DesignOperationalModel", []);
  const modelsChange = _.get(changes, "DesignInfo.DesignOperationalModelList.DesignOperationalModel");
  const perspects = _.get(designModule, "DesignInfo.DesignTimePerspectiveList.DesignTimePerspective", []);
  const perspectsChange = _.get(changes, "DesignInfo.DesignTimePerspectiveList.DesignTimePerspective");
  const phases = _.get(designModule, "PhaseList.Phase", []);
  const phasesChange = _.get(changes, "PhaseList.Phase");
  const allocation = _.get(designModule, "DesignInfo.DesignAllocation");
  const allocationChange = _.get(changes, "DesignInfo.DesignAllocation");
  const intModel = _.get(designModule, "DesignInfo.DesignInterventionModel");
  const intModelChange = _.get(changes, "DesignInfo.DesignInterventionModel");
  const intDesc = _.get(designModule, "DesignInfo.DesignInterventionModelDescription");
  const intDescChange = _.get(changes, "DesignInfo.DesignInterventionModelDescription");
  const purpose = _.get(designModule, "DesignInfo.DesignPrimaryPurpose");
  const purposeChange = _.get(changes, "DesignInfo.DesignPrimaryPurpose");
  const masking = _.get(designModule, "DesignInfo.DesignMaskingInfo.DesignMasking");
  const maskingChange = _.get(changes, "DesignInfo.DesignMaskingInfo.DesignMasking");
  const maskingDesc = _.get(designModule, "DesignInfo.DesignMaskingInfo.DesignMaskingDescription");
  const maskingDescChange = _.get(changes, "DesignInfo.DesignMaskingInfo.DesignMaskingDescription");
  const whoMasked = _.get(designModule, "DesignInfo.DesignMaskingInfo.DesignWhoMaskedList.DesignWhoMasked", []);
  const whoMaskedChange = _.get(changes, "DesignInfo.DesignMaskingInfo.DesignWhoMaskedList.DesignWhoMasked");
  const enrollment = _.get(designModule, "EnrollmentInfo.EnrollmentCount");
  const enrollmentChange = _.get(changes, "EnrollmentInfo.EnrollmentCount");
  const enrollmentType = _.get(designModule, "EnrollmentInfo.EnrollmentType");
  const enrollmentTypeChange = _.get(changes, "EnrollmentInfo.EnrollmentType");

  // TODO: move this logic out into a separate component maybe?
  let enrollmentString = enrollment;
  const enrollmentStringChange = {};
  if (enrollment && enrollmentType) {
    enrollmentString = `${enrollment} (${enrollmentType})`;
    const enrollmentChangeType = _.get(enrollmentChange, "changeType");
    const enrollmentTypeChangeType = _.get(enrollmentTypeChange, "changeType");
    if (enrollmentChangeType == "change" || enrollmentTypeChangeType == "change") {
      const oldEnrollment = _.get(enrollmentChange, "oldValue", enrollment);
      const oldType = _.get(enrollmentTypeChange, "oldValue", enrollmentType);
      enrollmentStringChange.oldValue = `${oldEnrollment} (${oldType})`;
      enrollmentStringChange.changeType = "change";
    } else if (enrollmentChangeType == "add" || enrollmentTypeChangeType == "add") {
      enrollmentStringChange.changeType = "add";
    } 
  }
  return <Wrapper>
    <ChangedValueWithLabel label="Study Type" newValue={studyType} {...studyTypeChange} />
    {patientRegistry && <ChangedValueWithLabel label="Patient Registry" newValue={patientRegistry} {...patientRegistryChange}/>}
    {duration && <ChangedValueWithLabel label="Target Duration" newValue={duration} {...durationChange}/>}
    {enrollmentString && <ChangedValueWithLabel label="Enrollment Count" newValue={enrollmentString} {...enrollmentStringChange}/>}
    <ListView label="Phases" values={phases}/>
    {models.length > 0 && <ListView label="Observational Models" values={models} changes={modelsChange}/>}
    {perspects.length > 0 && <ListView label="Time Perspectives" values={perspects} changes={perspectsChange}/>}
    {allocation && <ChangedValueWithLabel label="Allocation" newValue={allocation} {...allocationChange}/>}
    {intModel && <ChangedValueWithLabel label="Intervention Model" newValue={intModel} {...intModelChange}/>}
    {intDesc && <ChangedValueWithLabel label="Intervention Details" newValue={intDesc} {...intDescChange}/>}
    {purpose && <ChangedValueWithLabel label="Primary Purpose" newValue={purpose} {...purposeChange}/>}
    {masking && <ChangedValueWithLabel label="Masking" newValue={masking} {...maskingChange}/>}
    {maskingDesc && <ChangedValueWithLabel label="Masking Details" newValue={maskingDesc} {...maskingDescChange}/>}
    {whoMasked.length > 0 && <ListView label="Masked By" values={whoMasked} changes={whoMaskedChange}/>}
  </Wrapper>
}

export default DesignView;
