import React from 'react';
import api from '../api';
import _ from "lodash";
import styled from 'styled-components';
import CollapsibleView from './CollapsibleView';
import Select from 'react-select';


const NotificationWrapper = styled.div``

const Heading = 'Get Notified of Changes to Conditions By Email';

class ConditionSubscriptionSignup extends React.Component {

  constructor(props) {
    super(props);

    let selectOptions = [];
    api.getConditions()
    .then(res => {
        if (res.data)
        {
            _.forEach(res.data, function(arrayItem)
            {
                if (Array.isArray(arrayItem))
                {
                    _.forEach(arrayItem, function(option) {
                        selectOptions.push({value: option, label: option});
                    })
                }
                
            })
            this.setState({options: selectOptions});
        }
    })

    this.state = { options: [], subscribedConditions: [], selectedOption: null };
  }

  setEmailHandler = (event) => {
    this.setState({email: event.target.value});
  }

  setConditionHandler = selectedOption => {
    this.setState(
      { selectedOption },
    );
  };

  resetPage = (e) => {
      e.preventDefault();
      this.setState({success: false, unsubscribed: false, alreadySubscribed: false, codeSent: false, selectedOption: null, email: null});
  }

  unsubscribeCondition = (event) => {
    const {email, selectedOption} = this.state;

    event.preventDefault();
    api.unsubscribeToCondition(email, selectedOption.value[0])
    .then(res => {
        if (res.data.success)
        {
            this.setState({unsubscribed: true});
        }
    })
    
  }

  processVerification = (event) => {
    const {email, selectedOption} = this.state;
    event.preventDefault();
    let code = event.target[0].value;
    api.verifyEmail(code)
    .then(res => {
      api.subscribeToCondition(email, selectedOption.value[0])
      .then(res => {
          if (res.data.success)
          {
              this.setState({codeSent: false, success: true});
          }
      })
    })
}

  saveConditionNotification = (e) => {
    const {email, selectedOption} = this.state;
    e.preventDefault();
    if (email !== undefined && selectedOption != null)
    {
        api.getSubByEmail(email)
        .then(res =>
        {
            let subscribedConditions = res.data.data.conditions;
            if (subscribedConditions.includes(selectedOption.value[0]))
            {
                this.setState({alreadySubscribed: true})
            }
            else {
                api.isVerified(email)
                .then(res => {
                    api.subscribeToCondition(email, selectedOption.value)
                    .then(res => {
                        if (res.data.success)
                        {
                            this.setState({success: true});
                        }
                    })
                }).catch(error => {
                    if (error.response) {
                        api.sendVerEmail(email)
                        .then(res => {
                            this.setState({codeSent: true})
                        });
                    }
                });
            }
        }).catch(error => {
            if (error.response) {
                api.isVerified(email)
                .then(res => {
                    api.subscribeToCondition(email, selectedOption.value)
                    .then(res => {
                        if (res.data.success)
                        {
                            this.setState({success: true});
                        }
                    })
                }).catch(error => {
                    if (error.response) {
                        api.sendVerEmail(email)
                        .then(res => {
                            this.setState({codeSent: true})
                        });
                    }
                });
            }
        });
    }
  }

  render() {
    const { selectedOption } = this.state;
    if (this.state.unsubscribed == true)
    {
        return (
            <NotificationWrapper>
                <CollapsibleView open={true} heading={Heading}>
                    <p>You have been unsubscribed from the condition {this.state.selectedOption.value[0]}.</p>
                    <p><a href="#" onClick={this.resetPage}>Subscribe to Another Condition</a></p>
                </CollapsibleView>
            </NotificationWrapper>
        )
    }
    if (this.state.codeSent == true)
    {
        return (
            <NotificationWrapper>
                <CollapsibleView open={true} heading={Heading}>
                    <p>Please enter the verification code that we sent to your email address to confirm and subscribe to this condition. You will only need to do this once.</p>
                    <form method="post" onSubmit={this.processVerification}>
                        <input name="code" placeholder='' size="10" type='text' />
                        <input type='submit' value='Verify My Email and Subscribe to Condition' />
                        <input name="condition" type='hidden' value={this.state.selectedOption.value[0]} />
                    </form>
                </CollapsibleView>
            </NotificationWrapper>
        )      
    }
    if (this.state.alreadySubscribed == true)
    {
        return (
            <NotificationWrapper>
                <CollapsibleView open={true} heading={Heading}>
                    <p>You are already subscribed to this condition. To unsubscribe from this condition, click on the unsubscribe button below.</p>
                    <form method="post" onSubmit={this.unsubscribeCondition}>
                        <input type='submit' value='Unsubscribe me from this condition' />
                        <input name="condition" type='hidden' value={this.state.selectedOption} />
                    </form>
                </CollapsibleView>
            </NotificationWrapper>
        )
    }
    if (this.state.success == true)
    {
        return (
            <NotificationWrapper>
                <CollapsibleView open={true} heading={Heading}>
                    <p>You have been subscribed to the condition {this.state.selectedOption.label[0]}. When there are changes made, you will be notified by email.</p>
                    <p><a href="#" onClick={this.resetPage}>Subscribe to Another Condition</a></p>
                </CollapsibleView>
            </NotificationWrapper>
        );
    }
    else {
        return(
            <form method="post" onSubmit={this.saveConditionNotification}>
                <div class="form-group">
                    <div class="form-label" for="email">Email Address</div>
                    <input class="form-control" name="email" onChange={this.setEmailHandler} placeholder='Enter your E-mail address' size="60" type='email' />
                </div>
                <div class="form-group">
                    <div class="form-label" for="conditions">Conditions</div>
                    <Select
                        class="form-control"
                        name="conditions"
                        isSearchable="true"
                        options={this.state.options}
                        value={selectedOption}
                        onChange={this.setConditionHandler}
                    />
                </div>
                <input class="btn btn-primary btn-block btn-lg" type='submit' value="Save Notification" />
            </form>
        )
    }
  }
}

export default ConditionSubscriptionSignup