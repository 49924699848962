import React from 'react';
import _ from "lodash";
import ChangedValueWithLabel from "./ChangedValueWithLabel";
import CollapsibleView from './CollapsibleView';
import styled from 'styled-components';
const Wrapper = styled.div`
    width: 90%;
    margin: auto;
`
const SubHeading = styled.h5`
    text-align: center;
`

function ContactView(props) {
    const {data, prefix, changes} = props;
    const name = _.get(data, prefix + "Name");
    const nameChange = _.get(changes, prefix + "Name");
    const role = _.get(data, prefix + "Role");
    const roleChange = _.get(changes, prefix + "Role");
    const phone = _.get(data, prefix + "Phone");
    const phoneChange = _.get(changes, prefix + "Phone");
    const phoneExt = _.get(data, prefix + "PhoneExt");
    const phoneExtChange = _.get(changes, prefix + "PhoneExt");
    const timeFrame = _.get(data, prefix + "TimeFrame");
    const timeFrameChange = _.get(changes, prefix + "TimeFrame");
    const email = _.get(data, prefix + "EMail");
    const emailChange = _.get(changes, prefix + "EMail");
    return <div>
        <ChangedValueWithLabel label="Name" newValue={name} {...nameChange}/>
        <ChangedValueWithLabel label="Role" newValue={role} {...roleChange}/>
        <ChangedValueWithLabel label="Phone" newValue={phone} {...phoneChange}/>
        <ChangedValueWithLabel label="Phone Extension" newValue={phoneExt} {...phoneExtChange}/>
        <ChangedValueWithLabel label="Email" newValue={email} {...emailChange}/>
        <hr/>
    </div>
}

function OverallOfficialView(props) {
    const {data, changes} = props;
    const name = _.get(data, "OverallOfficialName");
    const nameChange = _.get(changes, "OverallOfficialName");
    const role = _.get(data, "OverallOfficialRole");
    const roleChange = _.get(changes, "OverallOfficialRole");
    const affiliation = _.get(data, "OverallOfficialAffiliation");
    const affiliationChange = _.get(changes, "OverallOfficialAffiliation");
    return <div>
        <ChangedValueWithLabel label="Name" newValue={name} {...nameChange}/>
        <ChangedValueWithLabel label="Affiliation" newValue={affiliation} {...affiliationChange}/>
        <ChangedValueWithLabel label="Role" newValue={role} {...roleChange}/>
        <hr/>
    </div>
}
function LocationView(props) {
    const {data, changes} = props;
    const facility = _.get(data, "LocationFacility");
    const facilityChange = _.get(changes, "LocationFacility");
    const status = _.get(data, "LocationStatus");
    const statusChange = _.get(changes, "LocationStatus");
    const city = _.get(data, "LocationCity");
    const cityChange = _.get(changes, "LocationCity");
    const state = _.get(data, "LocationState");
    const stateChange = _.get(changes, "LocationState");
    const country = _.get(data, "LocationCountry");
    const countryChange = _.get(changes, "LocationCountry");
    const zip = _.get(data, "LocationZip");
    const zipChange = _.get(changes, "LocationZip");
    const contacts = _.get(data, "LocationContactList.LocationContact", []);
    const contactsChange = _.get(changes, "LocationContactList.LocationContact");
    return <div>
        <ChangedValueWithLabel label="Facility" newValue={facility} {...facilityChange}/>
        <ChangedValueWithLabel label="Status" newValue={status} {...statusChange}/>
        <ChangedValueWithLabel label="City" newValue={city} {...cityChange}/>
        <ChangedValueWithLabel label="State" newValue={state} {...stateChange}/>
        <ChangedValueWithLabel label="Country" newValue={country} {...countryChange}/>
        <ChangedValueWithLabel label="Zip" newValue={zip} {...zipChange}/>
        {contacts.length > 0 && <CollapsibleView heading="Overall Officials">
          {
            contacts.map((c, i) =>
                <ContactView prefix="LocationContact" data={c} changes={_.get(contactsChange, i)}/>
            )
          }
        </CollapsibleView>}
        <hr/>
    </div>
}

function ContactsLocationsView(props) {
  const clModule = props.data;
  const changes = props.changes;
  const officials = _.get(clModule, "OverallOfficialList.OverallOfficial", []);
  const officialsChange = _.get(changes, "OverallOfficialList.OverallOfficial");
  const contacts = _.get(clModule, "CentralContactList.CentralContact", []);
  const contactsChange= _.get(changes, "CentralContactList.CentralContact");
  const locations = _.get(clModule, "LocationList.Location", []);
  const locationsChange = _.get(changes, "LocationList.Location");
  return <Wrapper>
    {officials.length > 0 && <CollapsibleView heading="Overall Officials">
      {
        officials.map((o, i) =>
            <OverallOfficialView data={o} changes={_.get(officialsChange, i)}/>
        )
      }
    </CollapsibleView>}
    {contacts.length > 0 && <CollapsibleView heading="Central Contacts">
      {
        contacts.map((c, i) =>
            <ContactView prefix="CentralContact" data={c} changes={_.get(contactsChange, i)}/>
        )
      }
    </CollapsibleView>}
    {locations.length > 0 && <CollapsibleView heading="Locations">
      {
        locations.map((l, i) =>
            <LocationView data={l} changes={_.get(locationsChange, i)}/>
        )
      }
    </CollapsibleView>}
  </Wrapper>
}

export default ContactsLocationsView;
