import React from 'react';
import _ from "lodash";
import ListView from "./ListView";
import styled from 'styled-components';
const Wrapper = styled.div`
    width: 90%;
    margin: auto;
`
function ConditionView(props) {
  const condModule = props.data;
  const changes = props.changes;
  const conditions = _.get(condModule, "ConditionList.Condition", []);
  console.log(changes);
  console.log(conditions);
  const conditionsChange = _.get(changes, "ConditionList.Condition");
  return <Wrapper>
    <ListView label="Conditions" values={conditions} changes={conditionsChange}/>
  </Wrapper>
}

export default ConditionView;
