import React from 'react';
import _ from "lodash";
import styled from 'styled-components';
import ChangedValueWithLabel from "./ChangedValueWithLabel";
const Wrapper = styled.div`
    width: 90%;
    margin: auto;
`
function OversightView(props) {
  const osModule = props.data;
  const changes = props.changes;
  const hasDmc = _.get(osModule, "OversightHasDMC");
  const hasDmcChange = _.get(changes, "OversightHasDMC");
  const isRegDrug = _.get(osModule, "IsFDARegulatedDrug");
  const isRegDrugChange = _.get(changes, "IsFDARegulatedDrug");
  const isRegDevice = _.get(osModule, "IsFDARegulatedDevice");
  const isRegDeviceChange = _.get(changes, "IsFDARegulatedDevice");
  return <Wrapper>
    <ChangedValueWithLabel label="Product Manufactured in and Exported from the U.S." newValue={hasDmc} {...hasDmcChange}/>
    <ChangedValueWithLabel label="Studies a U.S. FDA-regulated Drug Product" newValue={isRegDrug} {...isRegDrugChange}/>
    <ChangedValueWithLabel label="Studies a U.S. FDA-regulated Device Product" newValue={isRegDevice} {...isRegDeviceChange}/>
  </Wrapper>
}

export default OversightView;
