import React from 'react';
import LabelValueView from "./LabelValueView";
import ChangedValueView from "./ChangedValueView";
import _ from "lodash";
function ListView(props){
  const label = props.label;
  const values = props.values;
  const changes = props.changes;
  console.log(changes);
  const list = <ul>
  {_.map(values, (v, i) => {
    console.log(v);
    const change = _.get(changes, i);
    return <li><ChangedValueView newValue={v} {...change}/></li>
  })}
  </ul>;
  return <LabelValueView label={label} value={list}/>
}
export default ListView;
