import React from 'react';
import Collapsible from 'react-collapsible';
import styles from './CollapsibleView.module.css';
import '@fortawesome/fontawesome-free/css/all.css';

function CollapsibleView(props) {
  return <Collapsible trigger={props.heading}
    className={styles.Collapsible}
    openedClassName={styles.Collapsible}
    contentInnerClassName={styles.Collapsible__contentInner}
    triggerClassName={styles.Collapsible__trigger}
    triggerOpenedClassName={styles.Collapsible__trigger}
    open={props.open}
    >
      {props.children}
  </Collapsible>
}

export default CollapsibleView;
