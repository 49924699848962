import React, { useState, useEffect, useMemo } from 'react';
import Dropdown from "react-dropdown";
import Select from 'react-select';
import styles from "./FilterDropdown.module.css";
import _ from "lodash";

const stringToOption = v => ({value: v, label: v});

function FilterDropdown(props) {
  const [serverOptions, setServerOptions] = useState([]);
  const options = useMemo(() => 
    _.concat(
      props.value != null ? [{value: null, label: "Clear selection"}] : [],
      props.favourites ? [{type: "group", name: "Favourites", items: props.favourites.map(stringToOption)}] : [],
      serverOptions.map(stringToOption)
    )
    , [props.value, props.favourites, serverOptions]
  );
  useEffect(() => {
    let cancelled = false;
    if (props.fetchData != null) {
      props.fetchData()
        .then(o => {
          if (!cancelled) setServerOptions(o.data.data);
        })
        .catch(e => {console.log(e)})
    }
    return () => {cancelled = true};
  }, []);
  if (props.useAutocomplete !== undefined || props.useAutocomplete == true)
  {
    var filterOptions = [{'label': 'Clear Selection', 'value': ''}];
    _.forEach(serverOptions, function(value) { 
      var item = {'label': value, 'value': value};
      filterOptions.push(item); 
    });

    return <Select
        value={filterOptions.filter(function(option) {
          return option.value === props.value;
        })}
        noOptionsMessage={() => "No options found"}
        onChange={props.onChange}
        options={filterOptions}
    />
  }
  else {
    return <Dropdown options={options} onChange={props.onChange} value={props.value} placeholder={"Select a value"} 
        className={styles.root}
        controlClassName={styles.control}
        placeholderClassName={styles.placeholder}
        menuClassName={styles.menu}
        arrowClassName={styles.arrow}
    />
  }
}

export default FilterDropdown
