import React from 'react';
import _ from "lodash";
import styled from 'styled-components';
import CollapsibleView from './CollapsibleView';
import ChangedValueView from './ChangedValueView';
const Wrapper = styled.div`
    width: 90%;
    margin: auto;
`
function DescriptionView(props) {
  const descModule = props.data;
  const changes = props.changes;
  const brief = _.get(descModule, "BriefSummary", "Summary not available.");
  const briefChanges = _.get(changes, "BriefSummary");
  const detailed = _.get(descModule, "DetailedDescription", "Description not available.");
  const detailedChanges = _.get(changes, "DetailedDescription");
  return <Wrapper>
    <CollapsibleView open={true} heading="Brief Summary">
      <ChangedValueView newValue={brief} {...briefChanges}/>
    </CollapsibleView>
    <CollapsibleView heading="Detailed Description">
      <ChangedValueView newValue={detailed} {...detailedChanges}/>
    </CollapsibleView>
  </Wrapper>
}

export default DescriptionView;
