import React from 'react';
import _ from "lodash";
import ChangedValueWithLabel from "./ChangedValueWithLabel";
import ResponsiblePartyView from "./ResponsiblePartyView";
import ListView from "./ListView";
import styled from 'styled-components';
const Wrapper = styled.div`
    width: 90%;
    margin: auto;
`
function SponsorCollaboratorsView(props) {
  const scModule = props.data;
  const changes = props.changes;
  const respParty = _.get(scModule, "ResponsibleParty");
  const respPartyChange = _.get(changes, "ResponsibleParty");
  const lsName = _.get(scModule, "LeadSponsor.LeadSponsorName");
  const lsNameChange = _.get(changes, "LeadSponsor.LeadSponsorName");
  const lsClass = _.get(scModule, "LeadSponsor.LeadSponsorClass");
  const lsClassChange = _.get(changes, "LeadSponsor.LeadSponsorClass");
  const collaborators = _.get(scModule, "CollaboratorList.Collaborator", [])
    .map(c => _.get(c, "CollaboratorName"))
    .filter(c => c != null);
  const collaboratorsChange = _.get(changes, "CollaboratorList.Collaborator", [])
    .map(c => _.get(c, "CollaboratorName"))
    .filter(c => c != null);
  return <Wrapper>
    <ResponsiblePartyView data={respParty} changes={respPartyChange}/>
    <hr/>
    <ChangedValueWithLabel label="Lead Sponsor Name" newValue={lsName} {...lsNameChange}/>
    <ChangedValueWithLabel label="Lead Sponsor Class" newValue={lsClass} {...lsClassChange}/>
    <ListView label="Collaborators" values={collaborators} {...collaboratorsChange} />
  </Wrapper>
}

export default SponsorCollaboratorsView;
