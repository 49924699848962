import React, { Component } from 'react';
import ReactTable from 'react-table-6';
import api from '../api';
import _ from "lodash";
import {FilterDropdown} from "../components";
import {Link} from "react-router-dom";

import styled from 'styled-components'

import 'react-table-6/react-table.css'
import '../style/TrialsList.css'

const Wrapper = styled.div`
    padding: 20px 20px 20px 20px;
    width: 90vw;
    position: absolute;
`

class TrialsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            trials: [],
            columns: [],
            isLoading: false,
            pages: -1,
            defualtFilter: [],
        }

        if(this.props.location.state !== undefined){
            if("name" in this.props.location.state && this.props.location.state.name !== ""){
                this.state.defualtFilter.push({id:"briefTitle", value:this.props.location.state.name})
            }
            if("status" in this.props.location.state && this.props.location.state.status !== ""){
                this.state.defualtFilter.push({id:"status", value:this.props.location.state.status})
            }
            if("condition" in this.props.location.state && this.props.location.state.condition !== ""){
                this.state.defualtFilter.push({id:"condition", value:this.props.location.state.condition})
            }
            if("piInstitution" in this.props.location.state && this.props.location.state.piInstitution !== ""){
                this.state.defualtFilter.push({id:"piInstitution", value:this.props.location.state.piInstitution})
            }
            if("piName" in this.props.location.state && this.props.location.state.piName !== ""){
                this.state.defualtFilter.push({id:"piName", value:this.props.location.state.piName})
            }
            if("country" in this.props.location.state && this.props.location.state.country !== ""){
                this.state.defualtFilter.push({id:"country", value:this.props.location.state.country})
            } 
        }
    }

    onFetch = async (state, instance) => {
        this.setState({ isLoading: true })
        const filtered = _.assign({}, ...(_.map(state.filtered, ({id, value}) => ({[id]: value}))));
        console.log(state.sorted);
        const sort = _.get(state, "sorted[0]");
        const sortBy = _.get(sort, "id") ? _.get(sort, "id") : "lastUpdated";
        const order = _.get(sort, "desc") ? "desc" : sort !== undefined ? "asc" : "desc";
        
        await api.getAllTrials(state.page+1, state.pageSize, filtered, sortBy, order).then(trials => {
            this.setState({
                trials: trials.data.data,
                isLoading: false,
                pages: trials.data.pageCount
            })
        })
    }

    render() {
        const { trials, isLoading } = this.state
        console.log('TCL: TrialsList -> render -> trials', trials)

        const columns = [
            {
                Header: 'Trial Name',
                accessor: 'briefTitle',
            },
            {
                Header: 'Last Updated',
                id: 'lastUpdated',
                accessor: data => new Date(data.lastUpdated).toDateString(),
            },
            {
                Header: 'Status',
                accessor: 'status',
                filterable: true,
                Filter: ({filter, onChange}) => 
                    <FilterDropdown favourites={["Recruiting"]} useAutocomplete="true" fetchData={api.getStatuses} onChange={({value}) => {onChange(value);}} value={filter ? filter.value : null}/>,
                headerStyle: {"overflow": "visible"}
            },
            {
                Header: 'Condition/Disease',
                id: "condition",
                accessor: data => Array.from(new Set(data.condition)).join(", "),
                filterable: true,
                Filter: ({filter, onChange}) => 
                    <FilterDropdown favouries={["United States"]} useAutocomplete="true" fetchData={api.getConditions} onChange={({value}) => {onChange(value);}} value={filter ? filter.value : null}/>,
                headerStyle: {"overflow": "visible"}
            },
            {
                Header: 'Country',
                id: "country",
                accessor: data => Array.from(new Set(data.country)).join(", "),
                filterable: true,
                Filter: ({filter, onChange}) => 
                    <FilterDropdown favouries={["United States"]} useAutocomplete="true" fetchData={api.getCountries} onChange={({value}) => {onChange(value);}} value={filter ? filter.value : null}/>,
                headerStyle: {"overflow": "visible"}
            },
            {
                Header: 'PI Institution',
                accessor: 'piInstitution',
                filterable: true
            },

            {
                Header: 'PI Name',
                accessor: 'piName',
                filterable: true
            },
            {
                Header: 'View',
                accessor: 'NCTId',
                filterable: false,
                Cell: ({value}) => <Link to={{pathname: "/trial/" + value, state: {name: "", status:"", condition:"Multiple Myeloma",piInstitution:"",piName:"", country:"United States" }}}>View</Link>
            },
        ]


        return (
            <Wrapper>           
                <ReactTable
                    data={trials}
                    pages={this.state.pages}
                    columns={columns}
                    loading={isLoading}
                    defaultPageSize={10}
                    showPageSizeOptions={true}
                    manual
                    minRows={0}
                    onFetchData={this.onFetch}
                    defaultFiltered={this.state.defualtFilter}
                    getTableProps={() => ({style: {overflow: "visible"}})}
                />
            </Wrapper>
        )
    }
}

export default TrialsList;
