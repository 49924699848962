import React from 'react';
import styles from "./LabelValueView.module.css";

const LabelValueView = (props) => <div className={styles.row}>
  <div className={styles.leftColumn}>
    {props.label}:
  </div>
  <div className={styles.rightColumn}>
    {props.value}
  </div>
</div>;

export default LabelValueView;
