import React from 'react';
import _ from "lodash";
import ChangedValueWithLabel from "./ChangedValueWithLabel";
import styled from 'styled-components';
const Wrapper = styled.div`
    width: 90%;
    margin: auto;
`
function StatusView(props) {
  const statusModule = props.data;
  const changes = props.changes;
  const postDate = _.get(statusModule, "StudyFirstPostDateStruct.StudyFirstPostDate");
  const postDateChanges = _.get(changes, "StudyFirstPostDateStruct.StudyFirstPostDate");
  const updateDate = _.get(statusModule, "LastUpdatePostDateStruct.LastUpdatePostDate");
  const updateDateChanges = _.get(changes, "LastUpdatePostDateStruct.LastUpdatePostDate");
  const verifiedDate = _.get(statusModule, "StatusVerfiedDate");
  const verifiedDateChanges = _.get(changes, "StatusVerfiedDate");
  const overallStatus = _.get(statusModule, "OverallStatus");
  const overallStatusChanges = _.get(changes, "OverallStatus");
  return <Wrapper>
    <ChangedValueWithLabel label="First Post Date" newValue={postDate} {...postDateChanges}/>
    <ChangedValueWithLabel label="Last Update Date" newValue={updateDate} {...updateDateChanges}/>
    <ChangedValueWithLabel label="Overall Status" newValue={overallStatus} {...overallStatusChanges}/>
    <ChangedValueWithLabel label="Status Verfied Date" newValue={verifiedDate} {...verifiedDateChanges}/>
  </Wrapper>
  // TODO: MORE FIELDS HERE
}

export default StatusView;
