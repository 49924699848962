import React from 'react';
import _ from "lodash";
import LabelValueView from "./LabelValueView";
import ChangedValueWithLabel from "./ChangedValueWithLabel";

import styled from 'styled-components';
const Wrapper = styled.div`
    width: 90%;
    margin: auto;
`
const SubHeading = styled.h5`
    text-align: center;
`

const List = styled.li`
  list-style: none;
`

function ArmView(props) {
  const {data, changes, interventions, intChanges} = props;
  const label = _.get(data, "ArmGroupLabel");
  const labelChange = _.get(changes, "ArmGroupLabel");
  const type = _.get(data, "ArmGroupType");
  const typeChange = _.get(changes, "ArmGroupType");
  const description = _.get(data, "ArmGroupDescription");
  const descriptionChange = _.get(changes, "ArmGroupDescription");
  const armInterventions = _.at(interventions, _.get(data, "ArmGroupInterventionList.ArmGroupInterventionName", []))
  const armInterventionChanges = _.at(intChanges, _.get(data, "ArmGroupInterventionList.ArmGroupInterventionName", []))
  return <div> 
    <hr/>
    <ChangedValueWithLabel label="Arm Label" newValue={label} {...labelChange} />
    <ChangedValueWithLabel label="Arm Type" newValue={type} {...typeChange} />
    <ChangedValueWithLabel label="Arm Description" newValue={description} {...descriptionChange}/>
    <ul>
    {
      _.map(_.zip(armInterventions, armInterventionChanges), ([val, change]) =>
      <InterventionView data={val} changes={change}/>
      )   
    }
    </ul>
  </div>
}

function InterventionView(props) {
  const {data, changes} = props;
  console.log(data);
  const name = _.get(data, "InterventionName");
  const nameChange = _.get(changes, "InterventionName");
  const type = _.get(data, "InterventionType");
  const typeChange = _.get(changes, "InterventionType");
  const desc = _.get(data, "InterventionDescription");
  const descChange = _.get(changes, "InterventionDescription");
  return <List>
          <ChangedValueWithLabel label="Intervention Name" newValue={name} {...nameChange}/>
          <ChangedValueWithLabel label="Intervention Type" newValue={type} {...typeChange}/>
          <ChangedValueWithLabel label="Intervention Description" newValue={desc} {...descChange}/>
        </List>
}

const getIntKey =  i => `${_.get(i, "InterventionType")}: ${_.get(i, "InterventionName")}`;

function ArmsView(props) {
  const armsModule = props.data;
  const changes = props.changes;
  const arms = _.get(armsModule, "ArmGroupList.ArmGroup", []);
  const armsChange = _.get(changes, "ArmGroupList.ArmGroup");
  const interventionsList = _.get(armsModule, "InterventionList.Intervention", []);
  const interventions = _.keyBy(
    interventionsList,
    getIntKey
  );
  const interventionsChangeList = _.get(changes, "InterventionList.Intervention");
  const interventionsChange = {};
  _.forEach(interventionsList, (v, i) => {
    _.set(interventionsList, getIntKey(v), _.get(interventionsChangeList, i))
  });
  const armless = _.toPairs(_.pickBy(interventions, 
    v => _.isEmpty(_.get(v, "InterventionArmGroupLabelList.InterventionArmGroupLabel", []))));
  return <Wrapper>
    <SubHeading>Arms & Interventions </SubHeading>
    {
      arms.map((a, i) =>
        <ArmView 
          data={a}
          changes={_.get(armsChange, i)}
          interventions={interventions}
          intChanges={interventionsChange}
        />
      )
    }
    {armless.length > 0 && <div>
        Interventions without arms: 
        <ul>
          {armless.map(([k, v]) => <InterventionView data={v} changes={_.get(interventionsChange, k)}/>)}
        </ul>
    </div>}
  </Wrapper>
}

export default ArmsView;
