import React, { useState } from 'react';
import styled from 'styled-components';
import {Link} from "react-router-dom";
import {FilterDropdown} from "../components";
import api from '../api';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ConditionSubscriptionSignup from "../components/ConditionSubscriptionSignup";

const Wrapper = styled.div`
justify-content: center;
`

const Row = styled.div`
`

const RightCol = styled.div`
text-align: right;
text-align: center;
align-items: center;
`

const LeftCol = styled.div`
text-align: center;
justify-content: right;
align-items: right;
`

const ConditionSignup = styled.div`
margin: 15px 0 0;
`


function HomePage(){
    const [name, setName] = useState("");
    const [condition, setCondition] = useState("");
    const [status, setStatus] = useState("");
    const [country, setCountry] = useState("");
    const [piInstitution, setPiInstitution] = useState("");
    const [piName, setPiName] = useState("");
  
    return <Wrapper className="container">
                <Row className="row mt-4">
                    <RightCol className="col-md-6 mb-5 mb-md-auto">
                        <h5>
                            Alicanto Trials was developed by
                            The Division of Clinical Informatics
                            <a href={"http://www.hmfpinformatics.org"}> The Division of Clinical Informatics </a>
                            at Beth Israel Deaconess Medical Centre 
                            <a href={"https://www.bidmc.org"}> at Beth Israel Deaconess Medical Centre </a>
                        </h5>
                        <h6><a href = {"http://www.alicantocloud.com/"}>Alicanto</a> is an online platform for clinical teams for online learning, managing content, developing clinical practice guidelines, and managing clinical trials, and clinical consultations. For more information, contact Yuri Quintana, Ph.D., Chief of Division of Clinical Informatics. Email: yquintan@bidmc.harvard.edu</h6>
                        <Form.Label>Search Examples:</Form.Label>
                        <br/>
                        <Link to={{pathname:"/trials/list/", state: {name: "", status:"", condition:"Acute Myeloid Leukemia",piInstitution:"Beth Israel Deaconess Medical Center",piName:"", country:"" }}}>AML Trials at Beth Israel Deaconess Medical Center</Link>
                        <br/>
                        <Link to={{pathname:"/trials/list/", state: {name: "", status:"", condition:"Kidney Cancer",piInstitution:"Beth Israel Deaconess Medical Center",piName:"", country:"" }}}>Kidney Cancer Trials at Beth Israel Deaconess Medical Center</Link>
                        <br/>
                        <Link to={{pathname: "/trials/list/", state: {name: "", status:"Recruiting", condition:"Multiple Myeloma",piInstitution:"",piName:"", country:"United States" }}}>Multiple Myeloma Trials Recruting in United States</Link>

                    </RightCol>
                    <LeftCol className="col-md-6">
                        <Form style={{ width: "80%" }} >
                            <Form.Group size="lg" controlId="name">
                                <Form.Label>Clinical Trial Name</Form.Label>
                                <Form.Control
                                    autoFocus
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group size="lg" controlId="status">
                                <Form.Label>Status</Form.Label>
                                <FilterDropdown favourites={["Recruiting"]} useAutocomplete="true" fetchData={api.getStatuses} onChange={({value}) => {setStatus(value)}} value={status}/>
                            </Form.Group>
                            <Form.Group size="lg" controlId="condition">
                                <Form.Label>Disease/Condition</Form.Label>
                                <FilterDropdown favouries={["United States"]} useAutocomplete="true" fetchData={api.getConditions} onChange={({value}) => {setCondition(value);}} value={condition}/>
                            </Form.Group>
                            <Form.Group size="lg" controlId="piInstitution">
                                <Form.Label>Primary Institution</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={piInstitution}
                                    onChange={(e) => setPiInstitution(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group size="lg" controlId="piName">
                            <Form.Label>Primary Investigator</Form.Label>
                            <Form.Control
                                type="text"
                                value={piName}
                                onChange={(e) => setPiName(e.target.value)}
                            />
                            </Form.Group>
                            <Form.Group size="lg" controlId="country">
                            <Form.Label>Country</Form.Label>
                            <FilterDropdown favouries={["United States"]} useAutocomplete="true" fetchData={api.getCountries} onChange={({value}) => {setCountry(value)}} value={country}/>
                            </Form.Group>
                            <Link to={{pathname:"/trials/list/", state: {name: name, status:status, condition:condition,piInstitution:piInstitution,piName:piName,country:country }}}>
                                <Button block size="lg">
                                Search
                                </Button>
                            </Link>
                            {/* <Form.Group size="lg" controlId="demo">
                                <Form.Label>Search Examples:</Form.Label>
                                <br/>
                                <Link to={{pathname:"/trials/list/", state: {name: "", status:"", condition:"Acute Myeloid Leukemia",piInstitution:"Beth Israel Deaconess Medical Center",piName:"", country:"" }}}>AML Trials at Beth Israel Deaconess Medical Center</Link>
                                <br/>
                                <Link to={{pathname:"/trials/list/", state: {name: "", status:"", condition:"Kidney Cancer",piInstitution:"Beth Israel Deaconess Medical Center",piName:"", country:"" }}}>Kidney Cancer Trials at Beth Israel Deaconess Medical Center</Link>
                                <br/>
                                <Link to={{pathname: "/trials/list/", state: {name: "", status:"Recruiting", condition:"Multiple Myeloma",piInstitution:"",piName:"", country:"United States" }}}>Multiple Myeloma Trials Recruting in United States</Link>
                            </Form.Group> */}
                            </Form>
                        </LeftCol>
                    </Row>
            </Wrapper>;
        
    
}
export default HomePage;
