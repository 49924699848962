import React, { Component, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import api from '../api';
import _ from "lodash";
import IdView from "../components/IdView";
import StatusView from "../components/StatusView";
import SponsorCollaboratorsView from "../components/SponsorCollaboratorsView";
import ConditionView from "../components/ConditionView";
import OversightView from "../components/OversightView";
import DescriptionView from "../components/DescriptionView";
import DesignView from "../components/DesignView";
import EligibilityView from "../components/EligibilityView";
import ArmsView from "../components/ArmsView";
import OutcomesView from "../components/OutcomesView";
import ContactsLocationsView from "../components/ContactsLocationsView";
import styled from 'styled-components';
import { getSection } from '../util/changeUtils';
import TooltipView from "../components/TooltipView";
import EmailSubscriptionSignup from "../components/EmailSubscriptionSignup";

const Wrapper = styled.div`
    padding: 20px 20px 20px 20px;
    width: 70vw;
    margin: auto;
`

const Legend = styled.div`
  text-align: center;
`

function TrialView() {
  const [response, setResponse] = useState(null);
  const [diff, setDiff] = useState([]);
  const {id} = useParams();
  useEffect(() => {
    console.log("fetch trial");
    let cancelled = false;
    api.getTrialById(id)
      .then(res => {
        if (!cancelled)
          setResponse(res);
      });
    return () => {cancelled = true;};
  }, [id]);
  useEffect(() => {
    console.log("fetch diff");
    let cancelled = false;
    api.getDiffById(id)
      .then(res => {
        console.log(res);
        if (!cancelled && _.get(res, "data.data.type") === "modified")
          setDiff(_.get(res, "data.data.diff"), []);
      }).catch(e => {
        console.log(e);
        if (!cancelled)
          setDiff([])
      });
    return () => {cancelled = true;};
  }, [id]);
  const [idModule, idChanges] = getSection(response, diff, "ProtocolSection.IdentificationModule", null);
  const [statusModule, statusChanges] = getSection(response, diff, "ProtocolSection.StatusModule", null);
  const [scModule, scChanges] = getSection(response, diff, "ProtocolSection.SponsorCollaboratorsModule", null);
  const [condModule, condChanges] = getSection(response, diff, "ProtocolSection.ConditionsModule", null);
  const [osModule, osChanges] = getSection(response, diff, "ProtocolSection.OversightModule", null);
  const [descModule , descChanges] = getSection(response, diff, "ProtocolSection.DescriptionModule", null);
  const [designModule , designChanges] = getSection(response, diff, "ProtocolSection.DesignModule", null);
  const [eligModule , eligChanges] = getSection(response, diff, "ProtocolSection.EligibilityModule", null);
  const [armsModule, armsChanges] = getSection(response, diff, "ProtocolSection.ArmsInterventionsModule", null);
  const [outcomesModule, outcomesChanges] = getSection(response, diff, "ProtocolSection.OutcomesModule", null);
  const [clModule, clChanges] = getSection(response, diff, "ProtocolSection.ContactsLocationsModule", null);
  return <Wrapper>
    <Legend>
      <p>Look for the <TooltipView content="Recently changed content will display here." /> icon and hover over it to discover any items that have been changed since last view.</p>
    </Legend>
    <EmailSubscriptionSignup trialId={id} />
    {idModule && [<IdView data={idModule} changes={idChanges}/>, <hr/>]}
    {descModule && [<DescriptionView data={descModule} changes={descChanges}/>, <hr/>]}
    {condModule && [<ConditionView data={condModule} changes={condChanges}/>, <hr/>]}
    {scModule && [<SponsorCollaboratorsView data={scModule} changes={scChanges}/>, <hr/>]}
    {statusModule && [<StatusView data={statusModule} changes={statusChanges}/>, <hr/>]}
    {clModule && [<ContactsLocationsView data={clModule} changes={clChanges} />, <hr/>]}
    {eligModule && [<EligibilityView data={eligModule} changes={eligChanges}/>, <hr/>]}
    {designModule && [<DesignView data={designModule} changes={designChanges}/>, <hr/>]}
    {armsModule && [<ArmsView data={armsModule} changes={armsChanges}/>, <hr/>]}
    {outcomesModule && [<OutcomesView data={outcomesModule} changes={outcomesChanges}/>, <hr/>]}
    {osModule && [<OversightView data={osModule} changes={osChanges}/>, <hr/>]}
  </Wrapper>;
}

export default TrialView;
