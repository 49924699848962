import React from 'react';
import _ from "lodash";
import ChangedValueWithLabel from "./ChangedValueWithLabel";
import CollapsibleView from './CollapsibleView';
import styled from 'styled-components';
const Wrapper = styled.div`
    width: 90%;
    margin: auto;
`
const SubHeading = styled.h5`
    text-align: center;
`

function OutcomeView(props) {
    const {data, prefix, changes} = props;
    const measure = _.get(data, prefix + "Measure");
    const measureChange = _.get(changes, prefix + "Measure");
    const description = _.get(data, prefix + "Description");
    const descriptionChange = _.get(changes, prefix + "Description");
    const timeFrame = _.get(data, prefix + "TimeFrame");
    const timeFrameChange = _.get(changes, prefix + "TimeFrame");
    return <div>
        <ChangedValueWithLabel label="Measure" newValue={measure} {...measureChange}/>
        <ChangedValueWithLabel label="Description" newValue={description} {...descriptionChange}/>
        <ChangedValueWithLabel label="Time Frame" newValue={timeFrame} {...timeFrameChange}/>
        <hr/>
    </div>
}

function OutcomesView(props) {
  const outcomesModule = props.data;
  const changes = props.changes;
  const primary = _.get(outcomesModule, "PrimaryOutcomeList.PrimaryOutcome", []);
  const primaryChange = _.get(changes, "PrimaryOutcomeList.PrimaryOutcome");
  const secondary = _.get(outcomesModule, "SecondaryOutcomeList.SecondaryOutcome", []);
  const secondaryChange= _.get(changes, "SecondaryOutcomeList.SecondaryOutcome");
  return <Wrapper>
    <CollapsibleView heading="Primary Outcomes">
      {
        primary.map((o, i) =>
            <OutcomeView prefix="PrimaryOutcome" data={o} changes={_.get(primaryChange, i)}/>
        )
      }
    </CollapsibleView>
    <CollapsibleView heading="Secondary Outcomes">
      {
        secondary.map((o, i) =>
            <OutcomeView prefix="SecondaryOutcome" data={o} changes={_.get(secondaryChange, i)}/>
        )
      }
    </CollapsibleView>
  </Wrapper>
}

export default OutcomesView;
