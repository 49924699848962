import React from 'react';
import _ from "lodash";
import ListView from "./ListView";
import ChangedValueView from "./ChangedValueView";
import ChangedValueWithLabel from "./ChangedValueWithLabel";
import CollapsibleView from "./CollapsibleView";
import styled from 'styled-components';
const Wrapper = styled.div`
    width: 90%;
    margin: auto;
`
function EligibilityView(props) {
  const eligModule = props.data;
  const changes = props.changes;
  const ageGroups = _.get(eligModule, "StdAgeList.StdAge", []);
  const ageGroupsChange = _.get(changes, "StdAgeList.StdAge");
  const eligDesc = _.get(eligModule, "EligibilityCriteria");
  const eligDescChange = _.get(changes, "EligibilityCriteria");
  const minAge = _.get(eligModule, "MinimumAge", "None");
  const minAgeChange = _.get(changes, "MinimumAge");
  const maxAge = _.get(eligModule, "MaximumAge", "None");
  const maxAgeChange = _.get(changes, "MaximumAge");
  const genderBased = _.get(eligModule, "GenderBased");
  const genderBasedChange = _.get(changes, "GenderBased");
  const gender = _.get(eligModule, "Gender");
  const genderChange = _.get(changes, "Gender");
  const genderDesc = _.get(eligModule, "GenderDescription");
  const genderDescChange = _.get(changes, "GenderDescription");
  const healthyVolunteers = _.get(eligModule, "HealthyVolunteers");
  const healthyVolunteersChange = _.get(changes, "HealthyVolunteers");
  const studyPopulation = _.get(eligModule, "StudyPopulation");
  const studyPopulationChange = _.get(changes, "StudyPopulation");
  const sampling = _.get(eligModule, "SamplingMethod");
  const samplingChange = _.get(changes, "SamplingMethod");
  return <Wrapper>
    <CollapsibleView heading="Eligibility Criteria">
      <ChangedValueView newValue={eligDesc} {...eligDescChange}/>
    </CollapsibleView>
    {studyPopulation && <ChangedValueWithLabel label="Study Population" newValue={studyPopulation} {...studyPopulationChange}/>}
    {sampling && <ChangedValueWithLabel label="Sampling Method" newValue={sampling} {...samplingChange}/>}
    <ListView label="Eligible Age Groups" values={ageGroups} changes={ageGroupsChange}/>
    <ChangedValueWithLabel label="Minimum Age" newValue={minAge} {...minAgeChange}/>
    <ChangedValueWithLabel label="Maximum Age" newValue={maxAge} {...maxAgeChange}/>
    <ChangedValueWithLabel label="Accepts Healthy Volunteers" newValue={healthyVolunteers} {...healthyVolunteersChange}/>
    {genderBased && <ChangedValueWithLabel label="Gender-Based Eligibility" newValue={genderBased} {...genderBasedChange}/>}
    {gender && <ChangedValueWithLabel label="Gender" newValue={gender} {...genderChange}/>}
    {genderDesc && <ChangedValueWithLabel label="Gender Eligibility Details" newValue={genderDesc} {...genderDescChange}/>}
  </Wrapper>
}

export default EligibilityView;
