import React, { useState } from 'react';
import styled from 'styled-components';
import ConditionSubscriptionSignup from "../components/ConditionSubscriptionSignup";

const Wrapper = styled.div`
padding: 20px 120px 120px 220px;
width: 90vw;
position: absolute;
display: flex;
width: 100%;
justify-content: center;
`
const ConditionSignup = styled.div`
margin: 15px 0 0;
`
function ConditionSubscribe(){
  
    return <Wrapper>
                <ConditionSignup>
                    <ConditionSubscriptionSignup/>
                </ConditionSignup>
            </Wrapper>;
        
    
}
export default ConditionSubscribe;
