import React, { Component, useState, useEffect } from 'react';
import api from '../api';
import _ from "lodash";
import styled from 'styled-components';
import CollapsibleView from './CollapsibleView';
const Wrapper = styled.div`
    width: 90%;
    margin: auto auto 50px;
    text-align: center;
`

const Heading = 'Get Notified of Changes to this Trial By Email';


class EmailSubscriptionSignup extends React.Component {

  constructor(props) {
    super(props);

    var trialId = props.trialId;
    this.state = { trialId: trialId, success: false, codeSent: false, alreadySubscribed: false, unsubscribed: false, subscribedTrials: [] };
  }

  submitHandlerProcessUnsubscribe = (event) => {
    event.preventDefault();
    api.unsubscribeToTrial(this.state.email, this.state.trialId)
    .then(res => {
        if (res.data.success)
        {
            this.setState({unsubscribed: true});
        }
    })
    
  }

  submitHandlerProcessVerification = (event) => {
      event.preventDefault();
      let code = event.target[0].value;
      api.verifyEmail(code)
      .then(res => {
        api.subscribeToTrial(this.state.email, this.state.trialId)
        .then(res => {
            if (res.data.success)
            {
                this.setState({codeSent: false, success: true});
            }
        })
      })
  }

  submitHandler = (event) => {
    event.preventDefault();
    if (this.state.email !== undefined)
    {
        api.getSubByEmail(this.state.email)
        .then(res => {
            let trialId = this.state.trialId;
            let subscribedTrials = res.data.data.trials;

            if (subscribedTrials.indexOf(trialId) !== -1)
            {
                this.setState({alreadySubscribed: true})
            }
            else {
                api.isVerified(this.state.email)
                .then(res => {
                    api.subscribeToTrial(this.state.email, this.state.trialId)
                    .then(res => {
                        if (res.data.success)
                        {
                            this.setState({success: true});
                        }
                    })
                }).catch(error => {
                    if (error.response) {
                        api.sendVerEmail(this.state.email)
                        .then(res => {
                            this.setState({codeSent: true})
                        });
                    }
                });
            }
        }).catch(error => {
            if (error.response) {
                api.isVerified(this.state.email)
                .then(res => {
                    api.subscribeToTrial(this.state.email, this.state.trialId)
                    .then(res => {
                        if (res.data.success)
                        {
                            this.setState({success: true});
                        }
                    })
                }).catch(error => {
                    if (error.response) {
                        api.sendVerEmail(this.state.email)
                        .then(res => {
                            this.setState({codeSent: true})
                        });
                    }
                });
            }
        });
    }
  }

  changeHandler = (event) => {
    this.setState({email: event.target.value});
  }

  render() {
    if (this.state.unsubscribed == true)
    {
        return (
            <Wrapper>
                <CollapsibleView open={true} heading={Heading}>
                    <p>You have been unsubscribed from this trial.</p>
                </CollapsibleView>
            </Wrapper>
        )
    }
    if (this.state.codeSent == true)
    {
        return (
            <Wrapper>
                <CollapsibleView open={true} heading={Heading}>
                    <p>Please enter the verification code that we sent to your email address to confirm and subscribe to this trial. You will only need to do this once.</p>
                    <form method="post" onSubmit={this.submitHandlerProcessVerification}>
                        <input name="code" placeholder='' size="10" type='text' />
                        <input type='submit' value='Verify My Email and Subscribe to Trial' />
                        <input name="trialId" type='hidden' value={this.state.trialId} />
                    </form>
                </CollapsibleView>
            </Wrapper>
        )      
    }
    if (this.state.alreadySubscribed == true)
    {
        return (
            <Wrapper>
                <CollapsibleView open={true} heading={Heading}>
                    <p>You are already subscribed to this trial. To unsubscribe from this trial, click on the unsubscribe button below.</p>
                    <form method="post" onSubmit={this.submitHandlerProcessUnsubscribe}>
                        <input type='submit' value='Unsubscribe me from this trial' />
                        <input name="trialId" type='hidden' value={this.state.trialId} />
                    </form>
                </CollapsibleView>
            </Wrapper>
        )
    }
    if (this.state.success == true)
    {
        return (
            <Wrapper>
                <CollapsibleView open={true} heading={Heading}>
                    <p>We have subscribed you to this trial. When there are changes made, you will be notified by email.</p>
                </CollapsibleView>
            </Wrapper>
        )
    }
    else {
        return (
            <Wrapper>
                <CollapsibleView open={true} heading={Heading}>
                    <form method="post" onSubmit={this.submitHandler}>
                        <p>Enter your email address and press submit. If you haven't subscribed to a case before, you will need to confirm your email before your subscription notifications begin.</p>
                        <input name="email" onChange={this.changeHandler} placeholder='Enter your E-mail address' size="60" type='email' />
                        <input type='submit' value="Sign Up" />
                        <input name="trialId" type='hidden' value={this.state.trialId} />
                    </form>
                </CollapsibleView>
            </Wrapper>
        );
    }
  }
}

export default EmailSubscriptionSignup