import _ from "lodash";


export function getSection(response, changes, path, defVal=null) {
  const respSect = _.get(response, `data.data.${path}`, defVal);
  const pathSegs = _.split(path, ".");
  const newChanges = _.chain(changes)
    .filter(([type, changePath, val]) => _.startsWith(changePath, path))
    .map(([type, changePath, val]) => [type, _.drop(changePath.split("."), pathSegs.length), val])
    .value();
  const changeObj = {};
  _.forEach(newChanges, ([type, path, changeInfo]) => {
    if (type == "change") {
      // changeInfo is a tuple of oldValue, newValue
      _.set(changeObj, path, {changeType: type, oldValue: changeInfo[0]});
    } else {
      // changeInfo is list of tuples of form (propName|arrayIndex, value added or removed)
      // prop name needs to be appended to path
      _.forEach(changeInfo, ([prop, val]) => {
        const newPath = _.concat(path, prop);
        const oldVal = type === "remove" ? val : null;
        _.set(changeObj, newPath, {changeType: type, oldVal});
      });
    }
  });
  return [respSect, changeObj]
}
