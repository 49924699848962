import React from 'react';
import LabelValueView from "./LabelValueView";
import ChangedValueView from "./ChangedValueView";

function ChangedValueWithLabel(props) {
  const {label, newValue, oldValue, changeType} = props;
  return <LabelValueView label={label} 
    value={<ChangedValueView newValue={newValue} oldValue={oldValue} changeType={changeType}/>}
  />
}

export default ChangedValueWithLabel;
