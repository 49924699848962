import React from 'react';
import _ from "lodash";
import LabelValueView from "./LabelValueView";
import ChangedValueWithLabel from "./ChangedValueWithLabel";
import styled from 'styled-components';
const Wrapper = styled.div`
    width: 90%;
    margin: auto;
`
const Heading = styled.h3`
    text-align: center;
`
function IdView(props) {
  const idModule = props.data;
  const changes = props.changes;
  console.log(changes);
  const orgStudyId = _.get(idModule, "OrgStudyIdInfo.OrgStudyId");
  const orgStudyChanges = _.get(changes, "OrgStudyIdInfo.OrgStudyId");
  const orgName = _.get(idModule, "Organization.OrgFullName");
  const orgNameChanges = _.get(changes, "OrgStudyIdInfo.OrgFullName");
  const orgClass = _.get(idModule, "Organization.OrgClass");
  const orgClassChanges = _.get(changes, "OrgStudyIdInfo.OrgClass");
  const nctId = _.get(idModule, "NCTId");
  const briefTitle = _.get(idModule, "BriefTitle");
  const officialTitle = _.get(idModule, "OfficialTitle");
  return <Wrapper>
    <Heading>{officialTitle}</Heading>
    <LabelValueView label="NCT ID" value={nctId}/>
    <ChangedValueWithLabel label="Organization Name" newValue={orgName} {...orgNameChanges} />
    <ChangedValueWithLabel label="Organization Class" newValue={orgClass} {...orgClassChanges} />
    <ChangedValueWithLabel label="Organization Study ID" newValue={orgStudyId} {...orgStudyChanges} />
  </Wrapper>
}

export default IdView;
