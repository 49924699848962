import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import { NavBar } from '../components'
import TrialsList from '../pages/TrialsList'
import TrialView from '../pages/TrialView'
import HomePage from '../pages/HomePage'
import ConditionSubscribe from '../pages/ConditionSubscribe'

import 'bootstrap/dist/css/bootstrap.min.css'

function App() {
    return (
        <Router>
            <NavBar />
            <Switch>
                <Route path="/" exact>
                    <HomePage/>
                </Route>
                <Route path="/trials/list/" exact component={TrialsList} />
                <Route path="/trial/:id">
                  <TrialView/>
                </Route>
                <Route path="/condition">
                  <ConditionSubscribe/>
                </Route>
            </Switch>
        </Router>
    )
}

export default App
